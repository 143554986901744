<template>
	<div class="ele-body">
		<el-card shadow="never">
			<el-tabs v-model="active" class="user-info-tabs">
				<!--接单范围设置列表-->
				<el-tab-pane label="接单计价设置列表" name="course_data">
					<!-- 搜索表单 -->
					<el-form :model="table.where" class="ele-form-search d-flexspabet mt-20"
						@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
						<div style="margin-top: 20px;">
							<el-button @click="addBtn" class="ele-btn-icon addbtn " size="small"
								v-if="permission.includes('sys:limitnumbers:add')">添加接单计价设置</el-button>
						</div>
						<div class="d-flex" style="margin-top: 20px;">
							<el-form-item label="省:" label-width="35px" class="w-170">
								<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
									placeholder="请选择省" clearable>
									<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
										:key="option.pid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px" class="w-150">
								<el-select v-model="table.where.cid"
									@change="handleChangeCity(table.where.cid), $forceUpdate()" placeholder="请选择市"
									clearable>
									<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
										:key="option.cid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="62px" class="w-200">
								<el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区"
									clearable>
									<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
										:key="option.aid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
									class="ele-btn-icon ml-20">搜索</el-button>
								<el-button @click="(table.where = {}) && $refs.table.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
								show-overflow-tooltip />
							<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="type_name" label="类型" show-overflow-tooltip min-width="120" />
							<el-table-column prop="scope" label="接单范围（km）" show-overflow-tooltip min-width="160" />
							<el-table-column prop="assign" label="指定接单范围（km）" show-overflow-tooltip min-width="160" />
							<el-table-column prop="day_starting_price" label="白天起步价（元）" show-overflow-tooltip
								min-width="150" />
							<el-table-column prop="night_starting_price" label="黑天起步价（元）" show-overflow-tooltip
								min-width="150" />
							<el-table-column label="更新时间" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="status_name" label="审核状态" show-overflow-tooltip />
							<el-table-column label="操作" width="130px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link @click="edit(row, index)" icon="el-icon-edit" type="primary" :underline="false"
										v-if="permission.includes('sys:index.vue:edit')">编辑</el-link>
									<el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger"
										:underline="false" v-if="permission.includes('sys:index.vue:delete')">删除</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
				<!--审核列表-->
				<el-tab-pane label="审核列表" name="course_study">
					<ele-data-table ref="table1" :config="table1" :choose.sync="choose1" height="calc(100vh - 315px)"
						highlight-current-row class='mt-20' style="margin-top: 20px;" :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
								show-overflow-tooltip />
							<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="type_name" label="类型" show-overflow-tooltip min-width="120" />
							<el-table-column prop="scope" label="接单范围（km）" show-overflow-tooltip min-width="160" />
							<el-table-column prop="assign" label="指定接单范围（km）" show-overflow-tooltip min-width="160" />
							<el-table-column prop="day_starting_price" label="白天起步价（元）" show-overflow-tooltip
								min-width="150" />
							<el-table-column prop="night_starting_price" label="黑夜起步价（元）" show-overflow-tooltip
								min-width="150" />
							<el-table-column label="提交时间" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column label="操作" width="130px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link @click="scope_error(row)" slot="reference" icon="el-icon-error" type="danger"
										:underline="false"
										v-if="permission.includes('sys:order_scope:success')">驳回</el-link>
									<el-link @click="scope_success(row)" slot="reference" icon="el-icon-success"
										type="primary" :underline="false"
										v-if="permission.includes('sys:order_scope:error')">通过</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		<!--添加/编辑弹窗 -->
		<el-dialog v-dialogDrag :title="editForm.id ? '编辑接单计价设置' : '添加接单计价设置'" width="900px" :visible.sync="showEdit"
			@closed="editForm = {}" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="130px">
					<el-form-item label="类型：" prop="type">
						<el-select v-model="editForm.type" class="input163">
							<el-option value="1" label="司机"></el-option>
							<el-option value="2" label="跑腿"></el-option>
						</el-select>
					</el-form-item>
					<div>
						<el-form-item label="所属区域：" v-if="editForm.id" prop="pid">
							<el-select v-model="editForm.pname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择省"
								disabled v-if="editForm.pname">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市"
								disabled v-if="editForm.cname">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" class="selectStyle mb-20" clearable
								:class="(editForm.pname && editForm.cname) ? 'mr-10' : ''" placeholder="请选择区/县" disabled
								v-if="editForm.aname">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="所属区域：" v-if="!editForm.id" prop="pid">
							<el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder='请选择省'
								class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cid" @change="handleChangeCity1(editForm.cid)"
								class="selectStyle mr-10 mb-20" clearable placeholder="请选择市">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aid" @change="$forceUpdate(), handleDis(editForm.aid)"
								class="selectStyle mr-10 mb-20" clearable placeholder="请选择区/县">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>

					<el-form-item label="接单范围设置：" prop="scope">
						<el-input v-model="editForm.scope" placeholder="请输入接单范围" class='input163'
							oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
						km（接单范围需填写整数或最多两位小数）
					</el-form-item>
					<!-- <el-form-item label="指定叫单设置：" prop="assign" v-if="editForm.type==1">
						<el-input v-model="editForm.assign" placeholder="请输入接单范围" class="input163" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"/>
						km（指定范围需填写整数或最多两位小数）
					</el-form-item> -->
					<el-form-item label="指定叫单设置：" prop="assign">
						<el-input v-model="editForm.assign" placeholder="请输入接单范围" class="input163"
							oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
						km（指定范围需填写整数或最多两位小数）
					</el-form-item>

					<!-- <el-form-item label="接单计价设置：" prop="price">
						<el-input v-model="editForm.price" placeholder="请输入起步价格" class="input163"
							oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
						元（起步价需填写整数或最多两位小数）
					</el-form-item> -->



					<el-form-item label="起步价设置：">


						<div class="daytime_price_content">
							<el-radio v-model="radio" label="1">燃气/气车</el-radio>
							<el-switch v-model="editForm.automobile_status" active-value="true" inactive-value="false"
								@change="$forceUpdate()">
							</el-switch>


							<el-radio v-model="radio" label="2" style="margin-left: 20px;">新能源车</el-radio>
							<el-switch v-model="editForm.newenergy_status" active-value="true" inactive-value="false"
								@change="$forceUpdate()">
							</el-switch>
						</div>


						<div class="price" v-if="radio == 1">
							<div class="daytime_price">
								<div class="daytime_price_text">1）白天价格：</div>
								<div>
									<div class="time_period">

										<!-- <el-time-picker is-range v-model="editForm.daytime_starts" range-separator="~"
											format="HH:mm:ss" value-format="HH:mm:ss" start-placeholder="开始时间"
											end-placeholder="结束时间111" placeholder="选择时间范围"
											:default-time="['00:00:00', '23:59:59']" :picker-options="pickerOptions"
											type="datetimerange">
										</el-time-picker> -->

										<!-- 开始时间 ：<input type="time" v-model="editForm.daytime_start" value="开始时间">
										结束时间 ：<input type="time" v-model="editForm.daytime_end" value="结束时间"> -->

										<!-- <div style="margin-left: 0px;">
											开始时间 ：<input type="time" step="1" v-model="editForm.daytime_start"
												style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #DDDDDD"
												value="开始时间">
										</div> -->
										<div style="margin-left: 0px;">
											开始时间 ：<el-time-picker v-model="editForm.daytime_start" placeholder="任意时间点"
												value-format="HH:mm:ss">
											</el-time-picker>
										</div>
										<!-- <div style="margin-left: 10px;"> 结束时间 ：<input type="time" step="1"
												v-model="editForm.daytime_end"
												style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #DDDDDD;"
												value="结束时间">
										</div> -->
										<div style="margin-left: 10px;">
											结束时间 ：<el-time-picker v-model="editForm.daytime_end" placeholder="任意时间点"
												value-format="HH:mm:ss">
											</el-time-picker>
										</div>

									</div>
									<div class="daytime_price_content">
										<div class="starting_price">起步价</div>
										<el-input v-model="editForm.day_starting_price" placeholder="起步价金额" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>元 /</span>
										<el-input v-model="editForm.day_kilometre" placeholder="公里数" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>公里</span>
									</div>
									<div class="daytime_price_content">
										<div class="overprice">超出是</div>
										<el-input v-model="editForm.day_raise_price" placeholder="加价金额" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>元 / 公里</span>
									</div>
								</div>
							</div>
							<div class="daytime_price">
								<div class="daytime_price_text">2）黑天价格：</div>
								<div>
									<div class="time_period">
										<!-- <el-time-picker is-range v-model="editForm.night_starts" range-separator="~"
											format="HH:mm:ss" value-format="HH:mm:ss" start-placeholder="开始时间"
											end-placeholder="结束时间" placeholder="选择时间范围">
										</el-time-picker> -->
										<!-- <div style="margin-left: 0px;">
											开始时间 ：<input type="time" v-model="editForm.night_start" step="1"
												style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #DDDDDD"
												value="开始时间">
										</div> -->
										<div style="margin-left: 0px;">
											开始时间 ：<el-time-picker v-model="editForm.night_start" placeholder="任意时间点"
												value-format="HH:mm:ss">
											</el-time-picker>
										</div>
										<!-- <div style="margin-left: 10px;"> 结束时间 ：<input type="time"
												v-model="editForm.night_end" step="1"
												style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #DDDDDD;"
												value="结束时间">
										</div> -->
										<div style="margin-left: 10px;">
											结束时间 ：<el-time-picker v-model="editForm.night_end" placeholder="任意时间点"
												value-format="HH:mm:ss">
											</el-time-picker>
										</div>
									</div>
									<div class="daytime_price_content">
										<div class="starting_price">起步价</div>
										<el-input v-model="editForm.night_starting_price" placeholder="起步价金额"
											class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>元 /</span>
										<el-input v-model="editForm.night_kilometre" placeholder="公里数" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>公里</span>
									</div>
									<div class="daytime_price_content">
										<div class="overprice">超出是</div>
										<el-input v-model="editForm.night_raise_price" placeholder="加价金额" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>元 / 公里</span>
									</div>




									<!-- <el-form-item label="订单预估价展示：">
										<el-switch v-model="editForm.order_status" active-value="true"
											inactive-value="false" @change="$forceUpdate()">
										</el-switch>
									</el-form-item> -->


								</div>
							</div>
						</div>
					</el-form-item>



					<el-form-item label="">
						<div class="price" v-if="radio == 2">
							<div class="daytime_price">
								<div class="daytime_price_text">1）白天价格：</div>
								<div>
									<div class="time_period">

										<!-- <el-time-picker is-range v-model="editForm.daytime_starts" range-separator="~"
											format="HH:mm:ss" value-format="HH:mm:ss" start-placeholder="开始时间"
											end-placeholder="结束时间111" placeholder="选择时间范围"
											:default-time="['00:00:00', '23:59:59']" :picker-options="pickerOptions"
											type="datetimerange">
										</el-time-picker> -->

										<!-- 开始时间 ：<input type="time" v-model="editForm.daytime_start" value="开始时间">
										结束时间 ：<input type="time" v-model="editForm.daytime_end" value="结束时间"> -->

										<!-- <div style="margin-left: 0px;">
											开始时间 ：<input type="time" step="1" v-model="editForm.daytime_start"
												style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #DDDDDD"
												value="开始时间">
										</div> -->
										<div style="margin-left: 0px;">
											开始时间 ：<el-time-picker v-model="editForm.daytime_starts" placeholder="任意时间点"
												value-format="HH:mm:ss">
											</el-time-picker>
										</div>
										<!-- <div style="margin-left: 10px;"> 结束时间 ：<input type="time" step="1"
												v-model="editForm.daytime_end"
												style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #DDDDDD;"
												value="结束时间">
										</div> -->
										<div style="margin-left: 10px;">
											结束时间 ：<el-time-picker v-model="editForm.daytime_ends" placeholder="任意时间点"
												value-format="HH:mm:ss">
											</el-time-picker>
										</div>

									</div>
									<div class="daytime_price_content">
										<div class="starting_price">起步价</div>
										<el-input v-model="editForm.day_starting_prices" placeholder="起步价金额"
											class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>元 /</span>
										<el-input v-model="editForm.day_kilometres" placeholder="公里数" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>公里</span>
									</div>
									<div class="daytime_price_content">
										<div class="overprice">超出是</div>
										<el-input v-model="editForm.day_raise_prices" placeholder="加价金额" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>元 / 公里</span>
									</div>
								</div>
							</div>
							<div class="daytime_price">
								<div class="daytime_price_text">2）黑天价格：</div>
								<div>
									<div class="time_period">
										<!-- <el-time-picker is-range v-model="editForm.night_starts" range-separator="~"
											format="HH:mm:ss" value-format="HH:mm:ss" start-placeholder="开始时间"
											end-placeholder="结束时间" placeholder="选择时间范围">
										</el-time-picker> -->
										<!-- <div style="margin-left: 0px;">
											开始时间 ：<input type="time" v-model="editForm.night_start" step="1"
												style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #DDDDDD"
												value="开始时间">
										</div> -->
										<div style="margin-left: 0px;">
											开始时间 ：<el-time-picker v-model="editForm.night_starts" placeholder="任意时间点"
												value-format="HH:mm:ss">
											</el-time-picker>
										</div>
										<!-- <div style="margin-left: 10px;"> 结束时间 ：<input type="time"
												v-model="editForm.night_end" step="1"
												style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #DDDDDD;"
												value="结束时间">
										</div> -->
										<div style="margin-left: 10px;">
											结束时间 ：<el-time-picker v-model="editForm.night_ends" placeholder="任意时间点"
												value-format="HH:mm:ss">
											</el-time-picker>
										</div>
									</div>
									<div class="daytime_price_content">
										<div class="starting_price">起步价</div>
										<el-input v-model="editForm.night_starting_prices" placeholder="起步价金额"
											class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>元 /</span>
										<el-input v-model="editForm.night_kilometres" placeholder="公里数" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>公里</span>
									</div>
									<div class="daytime_price_content">
										<div class="overprice">超出是</div>
										<el-input v-model="editForm.night_raise_prices" placeholder="加价金额" class="input163"
											oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" />
										<span>元 / 公里</span>
									</div>


									<!-- <div class="daytime_price_content">
										<div class="overprice">订单预估价展示</div>
										<el-switch v-model="editForm.order_status" active-value="true"
											inactive-value="false" @change="$forceUpdate()">
										</el-switch>
									</div> -->

									<!-- <el-form-item label="订单预估价展示：">
										<el-switch v-model="editForm.order_status" active-value="true"
											inactive-value="false" @change="$forceUpdate()">
										</el-switch>
									</el-form-item> -->


								</div>
							</div>
						
						</div>
						<div class="daytime_price_content">
								<div class="overprice">订单预估价展示</div>
								<el-switch v-model="editForm.order_status" active-value="true" inactive-value="false"
									@change="$forceUpdate()">
								</el-switch>
							</div>
					</el-form-item>



					<el-form-item label="开启天气加价：">
						<el-switch v-model="editForm.weather_status" active-value="true" inactive-value="false"
							@change="$forceUpdate()">
						</el-switch>

		
						<div v-if="editForm.weather_status == 'true'">
							<el-radio-group v-model="editForm.weather" style="margin-left: 50px;">
								<el-radio :label="2">雨天</el-radio>
								<el-radio :label="3">雪天</el-radio>
							</el-radio-group>

							<div class="daytime_price_content">
								<div class="overprice">一档</div>
					
								<el-input v-model="editForm.first_gear" placeholder="加价金额" class="input163" />
								<span>元 </span>
							</div>
							<div class="daytime_price_content">
								<div class="overprice">二档</div>
								<el-input v-model="editForm.second_gear" placeholder="加价金额" class="input163" />
								<span>元 </span>
							</div>
							<div class="daytime_price_content">
								<div class="overprice">三档</div>
								<el-input v-model="editForm.three_gear" placeholder="加价金额" class="input163" />
								<span>元 </span>
							</div>
							<div class="daytime_price_content">
								<div class="overprice">四档</div>
								<el-input v-model="editForm.four_gear" placeholder="加价金额" class="input163" />
								<span>元 </span>
							</div>
							<div class="daytime_price_content">
								<div class="overprice">五档</div>
								<el-input v-model="editForm.five_gear" placeholder="加价金额" class="input163" />
								<span>元 </span>
							</div>
						</div>
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="winShow(0)">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	mapGetters
} from "vuex";
export default {
	name: "SysLimitNumbers",
	data() {
		return {
			table: {
				url: '/range/index',
				where: {}
			}, // 设置列表表格配置
			table1: {
				url: '/range/indexReview',
				where: {}
			},
			choose: [], // 表格选中数据
			choose1: [], // 表格选中数据
			showEdit: false, // 是否显示表单弹窗
			editForm: {},
			editForm1: {},
			editRules: { // 表单验证规则
				type: [{ required: true, message: '请选择类型', trigger: 'change' }],
				pid: [{ required: true, message: '请选择省份', trigger: 'change' }],
				scope: [{
					required: true,
					message: '请输入接单范围',
					trigger: 'blur'
				}],
				price: [
					{ required: true, message: '请输入起步价格', trigger: 'blur' },
				],
				assign: [
					{ required: true, message: '请输入接单范围', trigger: 'blur' },
				],
			},
			provArr: [],
			cityArr: [],
			districtArr: [],
			provArr1: [],
			// cityArr1:[],
			// districtArr1:[],
			active: 'course_data',
			activeEdit: 'run-driver',
			pid: '',
			cid: '',
			aid: '',
			pid1: '',
			cid1: '',
			aid1: '',
			changePro: false,
			pickerOptions: {

				disabledDate: (time) => {

					// 如果函数里处理的数据比较麻烦,也可以单独放在一个函数里,避免data数据太臃肿
					return this.dealDisabledDate(time);
				}
			},
			radio: '1'
		}
	},

	created() {
		this.$http.get('/common/province_list').then(res => {
			let data = JSON.parse(res.data)
			this.provArr = data

		})
		setTimeout(() => {
			//console.log('editForm',this.editForm);

		},2000)
	},
	computed: {
		...mapGetters(["permission"]),
	},
	mounted() {},
	methods: {
		dealDisabledDate(time) {
			// time 是一个new Date数据

			if (new Date(time).getTime() > new Date().getTime()) {
				return time.getTime() >= (new Date().getTime());//时间范围必须是时间戳
			} else {
				return time.getTime() < Date.now() - 2 * 8.64e7;//8.64e7就是一天的时间戳 24*60*60*1000   两天之内 根据自己需求来定
			}

		},
		addBtn() {
			this.showEdit = true
			this.editForm.weather_status = 'false'
		},
		switchChange(e) {

			if (e) {
				this.editForm.weather_status = 'true'
			} else {
				//console.log('到这里饿了')
				this.editForm.weather_status = 'false'
			}

			// this.editForm.weather_status = !this.editForm.weather_status
			//console.log(e)
			// //console.log(typeof(e))

		},
		open(e) {
			//console.log(e)
		},
		winShow(isShow) {
			this.$refs.table.reload()
			this.showEdit = isShow == 1 ? true : false;


		},
		tabClick(row) {
			if (row.name == 'run-driver') {
				this.table.where.set_status = 2
			} else if (row.name == 'run-errands') {
				this.table.where.set_status = 0
				this.table.url = '/range/index'
			}
		},
		/**
		 *选择省
		 **/
		handleChangeProv(e) {
			/** 获取被选省份的pid**/
			let pid = ''
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.cityArr = data
				/** 选择省份清空市县**/
				this.table.where.cid = ''
				this.table.where.aid = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity(e) {
			if (e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
			} else {
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
		},

		/**
		 *修改省
		 **/
		handleChangeProv1(e) {
			/** 获取被选省份的pid**/
			let pid = ''
			let that = this
			this.changePro = false
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				that.cityArr = data
				/** 选择省份清空市县**/
				this.editForm.cid = ''
				this.editForm.aid = ''
			})
		},
		/**
		 *修改市
		 **/
		handleChangeCity1(e) {
			if (e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = false
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aid = ''
				})
			} else {
				this.editForm.pid = ''
				this.editForm.aid = ''
			}
		},
		handleDis() {
			this.changePro = false
		},
		/**
		 * 显示编辑
		 */
		edit(row, index) {
			this.showEdit = true
			// if (row.type_name == '司机') {
			// 	this.activeEdit = 'run-driver'
			// } else {
			// 	this.activeEdit = 'run-errands'
			// }



			this.editForm = row

		},
		/**
		 * 保存编辑
		 */
		save() {
			// //console.log('33333333333333333');

			this.$refs['editForm'].validate((valid) => {
				// //console.log('editForm.daytime_time',this.editForm.daytime_time);
				if (valid) {
					if (this.editForm.price == "/" || !this.editForm.price) {
						if (Math.floor(parseFloat(this.editForm.scope)) === parseFloat(this.editForm.scope) || this.editForm.scope.split(".")[1].length <= 2) {
							const loading = this.$loading({ lock: true });
							this.$http.post('/range/edit', this.editForm).then(res => {
								loading.close();
								if (res.data.code === 0) {
									this.$message({
										type: 'success',
										message: res.data.msg
									});
									this.$refs.table.reload();
									this.$refs.table1.reload();
									this.showEdit = false;
								} else {
									this.$message.error(res.data.msg);
								}
							}).catch(e => {
								loading.close();
								this.$message.error(e.message);
							});
						} else {
							// this.$notify.error({
							// 	title: '错误',
							// 	message: '接单范围设置请填写整数或最多两位小数'
							// });
							this.$message.error('接单范围设置请填写整数或最多两位小数');
						}
					} else {
						if ((Math.floor(parseFloat(this.editForm.scope)) === parseFloat(this.editForm.scope) || this.editForm.scope.split(".")[1].length <= 2) &&
							(Math.floor(parseFloat(this.editForm.price)) === parseFloat(this.editForm.price) || this.editForm.price.split(".")[1].length <= 2)) {
							const loading = this.$loading({ lock: true });
							this.$http.post('/range/edit', this.editForm).then(res => {
								loading.close();
								if (res.data.code === 0) {
									this.$message({
										type: 'success',
										message: res.data.msg
									});
									this.$refs.table.reload();
									this.$refs.table1.reload();
									this.showEdit = false;
								} else {
									this.$message.error(res.data.msg);
								}
							}).catch(e => {
								loading.close();
								this.$message.error(e.message);
							});
						} else {
							this.$notify.error({
								title: '错误',
								message: '接单范围设置/起步价设置请填写整数或最多两位小数'
							});
						}
					}
				} else {
					return false;
				}
			});
		},
		scope_success(row) {
			this.$confirm('确认同意接单范围申请?', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/range/set_range_status', {
					id: row.id,
					set_status: 2
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
						this.$refs.table1.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})
		},
		scope_error(row) {
			this.$confirm('确认驳回接单范围申请?', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/range/set_range_status', {
					id: row.id,
					set_status: 1
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
						this.$refs.table1.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})
		},

		/**
		 * 刪除(批量刪除)
		 */
		remove(row) {
			if (!row) { // 批量删除
				if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
				let ids = this.choose.map(d => d.id);
				this.$confirm('确定要删除选中的接单范围吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/range/delete', {
						id: ids
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}).catch(() => 0);
			} else { // 单个删除
				this.$confirm('确定要删除选中的接单范围吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/range/delete', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			}
		},

	}
}
</script>

<style scoped>
.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
	width: 100%;
}

/deep/.el-tabs__nav {
	margin-left: 20px
}

.price {
	font-size: 16px;
	color: #595959;
}

.daytime_price {
	display: flex;
}

.daytime_price_text {
	width: 100px;
}

.time_period {
	display: flex;
	align-items: center;
}

.daytime_price_content {
	display: flex;
	align-items: center;
	margin: 10px 0;

}

.starting_price,
.overprice {
	margin-right: 10px;
}

.daytime_price_content span {
	margin: 0 10px;
}

.tilde {
	margin: 0 10px;
}

element.style {
	width: 124px;
}
</style>
